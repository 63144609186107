@import 'cssvars';

/* TYPOGRAPHY */

p { margin-bottom: 1.15rem; }

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.15;
  margin: 2.75rem 0 1.05rem;
  margin: 0;
}

h1 {
  color: var(--fg1);
  font-size: 2.488em;
  margin-top: 0;
}

h1,
h2,
h3 {
  font-family: var(--font-family-header);
  font-weight: 500;
}

h4,
h5 {
  font-family: var(--font-family-body);
  font-weight: 400;
}

h2 {font-size: 2.074em;}

h3 {font-size: 1.728em;}

h4 {font-size: 1.44em;}

h5 {font-size: 1.2em;}

small,
.text_small {font-size: 0.833em;}

.text-extra-small { font-size: x-small;}

span.code {
  background: rgba(255, 255, 255, 0.1);
  font-family: 'Inconsolata', monospace;
  opacity: 0.75;
}

/* MENUS */
.mat-menu-item {
  height: 36px !important;
  line-height: 36px !important;
}

.mat-menu-item.sticky-bottom,
.mat-option.sticky-bottom {
  background: var(--bg2);
  border-top: solid 1px var(--lines);
  bottom: 0;
  position: sticky;
}

.mat-menu-item.sticky-top,
.mat-option.sticky-top {
  background: var(--bg2);
  border-bottom: solid 1px var(--lines);
  position: sticky;
  top: 0;
}


/* Cards */

.mat-card {
  border: solid var(--highlights) rgba(255, 255, 255, 0.025);
}

.mat-card.mat-card-padded {
  padding: 16px 32px !important;
}

ix-entity-table .mat-card,
ix-entity-form .mat-card {
  padding: 0 !important;
}

.form-card.mat-card {
  margin: 0 auto;
  max-width: 960px;
}

ix-entity-form .mat-card > *,
ix-entity-table .mat-card > * {
  margin: 0 !important;
  padding: 16px !important;
}

.mat-card > .no-padding {
  padding: 0 !important;
}

.mat-card-title-text {
  margin: 0 !important;
}

.mat-toolbar .mat-button {
  height: 36px !important;
  margin: 0 4px;
}

.fn-toolbar {
  background: var(--alt-bg1);
}

/* Special Override Classes */

/* FreeNAS Specific */

/* This element is part of entity-table.
 * It currently uses css to do artificially
 * put itselt inside the title bar.
 * This needs to be changed in the markup
 * instead of using this type of hack */
#filter {
  margin-top: -10px; /* The form field reserves too much space in mat-toolbar. This wins some of the screen back */
  max-height: 38px;
  position: static !important;
  text-align: right;
}

ix-entity-table .multiactions-title {
  margin-bottom: 16px;
}

ix-entity-table .mat-toolbar.mat-card-toolbar {
  position: relative;
  z-index: 2;
}
/* Elements with shadows */
.datatable-header,
ix-entity-table .mat-toolbar.mat-card-toolbar {
  box-shadow: var(--fn-dropshadow);
}

/*********************************************************************/
/* Dematerialize Form Fields :) */
.form-element {
  margin: 16px 0 17px;
  text-align: left;
}

.form-element .label.input-text,
.form-element .label.input-select {
  font-size: x-small;
}

.form-element .label-container {
  align-items: center;
  display: flex;
  height: 24px;
  margin-bottom: 4px;
  padding: 4px 0;

  label {
    margin-right: 8px;
  }
}

/* Bring back the native placeholder */
input.mat-input-element::placeholder,
textarea.mat-input-element::placeholder {
  color: var(--fg2) !important;
  opacity: 0.5;
  -webkit-text-fill-color: var(--fg2) !important;
}

.borderless {
  border: none !important;
}

.mat-divider {
  background: var(--lines) !important;
  opacity: 1 !important;
}

.mat-divider-horizontal {
  border-top-color: var(--lines) !important;
}

.fieldset {
  padding-bottom: 24px;
}

.fieldset.empty-fieldset {
  padding-bottom: 0;
}

.full-width {
  position: relative;
}

.form-element.form-combobox .mat-form-field,
.form-element.form-ip-netmask .mat-form-field,
.form-element.form-combobox .mat-menu-trigger,
.form-element .mat-form-field-wrapper,
.form-element input[type = 'password'],
.form-element input[type = 'email'],
.form-element input[type = 'text'],
.form-element input[type = 'number'],
.form-element textarea,
.form-element select {
  background-color: var(--bg1);
  border: solid 1px var(--lines);
  box-sizing: border-box;
  color: var(--fg1);
  padding: 8px;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.38;
  }
}

.form-element,
#password,
.form-element.form-combobox .mat-form-field,
.form-element.form-ip-netmask .mat-form-field,
.form-element.form-combobox .mat-menu-trigger,
.form-element .mat-form-field-wrapper,
.form-element .form-button,
.form-element input[type = 'password'],
.form-element input[type = 'email'],
.form-element input[type = 'text'],
.form-element input[type = 'number'],
.form-element textarea,
.form-element select {
  line-height: normal !important;
  min-height: 33px;
  width: 100% !important;
}

ix-entity-form-list .list-action-block-wrapper .form-element,
ix-entity-form-list .list-action-block-wrapper #password,
ix-entity-form-list .list-action-block-wrapper .form-element.form-combobox .mat-form-field,
ix-entity-form-list .list-action-block-wrapper .form-element.form-ip-netmask .mat-form-field,
ix-entity-form-list .list-action-block-wrapper .form-element.form-combobox .mat-menu-trigger,
ix-entity-form-list .list-action-block-wrapper .form-element .mat-form-field-wrapper,
ix-entity-form-list .list-action-block-wrapper .form-element input[type = 'password'],
ix-entity-form-list .list-action-block-wrapper .form-element input[type = 'email'],
ix-entity-form-list .list-action-block-wrapper .form-element input[type = 'text'],
ix-entity-form-list .list-action-block-wrapper .form-element input[type = 'number'],
ix-entity-form-list .list-action-block-wrapper .form-element textarea,
ix-entity-form-list .list-action-block-wrapper .has-tooltip mat-form-field.full-width,
ix-entity-form-list .list-action-block-wrapper .form-element select {
  width: 100% !important;
}

/* Combo Box */
.form-element.form-combobox .label-container {
  margin-bottom: 0;
  padding-bottom: 3px;
}

.form-element.form-combobox .mat-form-field-wrapper input,
.form-element.form-combobox .mat-form-field-wrapper {
  background-color: unset;
  border: unset;
  padding: unset;
}

.form-element.form-combobox .mat-form-field {
  height: 33px;
  margin-right: 1px;
  max-width: calc(100% - 34px) !important;
  padding: 0 8px;
}

.form-element.form-combobox .mat-menu-trigger:hover {
  cursor: pointer;
}

.form-element.form-combobox .mat-menu-trigger {
  padding: 9px 2px 8px;
  position: relative;
  top: -9px;
}

.form-element.form-combobox .mat-menu-trigger .mat-icon {
  position: relative !important;
  top: 7px !important;
}

.form-element.form-combobox .mat-form-field,
.form-element.form-combobox .mat-menu-trigger {
  height: 33px;
}

/* IP w/Netmask*/
.form-element.form-ip-netmask .mat-form-field {
  max-height: 33px;
}

.form-element.form-ip-netmask .mat-form-field-wrapper input,
.form-element.form-ip-netmask .mat-form-field-wrapper {
  background-color: unset;
  border: unset;
  padding: unset;
}

.form-element.form-combobox .mat-select-trigger {
  padding: 9px 2px 8px;
  position: relative;
  top: 2px;
}

/* Form List */
.form-list .form-list-item {
  position: relative;
  right: 18px;
  top: 50%;
}

.form-list .list-action-inline {
  align-items: flex-start;
  display: flex;
  justify-content: center;
}

.form-list .list-action {
  display: flex;
  justify-content: right;
}

/* Chips */
.form-element .mat-chip {
  font-size: smaller !important;
  padding: 1px 16px 0 !important;
}

/* Login Form */
.login-form .form-element .mat-form-field-wrapper {
  max-height: 33px;
  min-height: unset;
  width: 100% !important;
}


.login-form .form-element .mat-form-field-wrapper input {
  background: none;
  border: none;
  margin: 0;
  padding: 0 0 2px;
}

.login-form .form-element .mat-form-field-prefix .mat-icon {
  font-size: 16px;
}

.login-form .form-element .mat-form-field-prefix {
  margin-bottom: 2px;
}

.login-form .form-element.username .mat-form-field-prefix {
  top: -4px;
}

.login-form .form-element.username .mat-form-field-flex,
.login-form .form-element.otp .mat-form-field-flex {
  align-items: flex-start;
  position: relative;
  top: -2px;
}

.login-form .form-element.password .mat-form-field-prefix {
  top: -11px;
}

.login-form .form-element input[type = 'password'],
.login-form .form-element .has_toggle_pw input {
  padding: 2px 0 0;
  top: -10px;
}

.login-form .form-element button.toggle_pw {
  bottom: 2px;
  position: absolute;
  right: 4px;
}

.form-element .mat-form-field-suffix {
  top: -2px;
}

.form-element textarea {
  max-width: 100%;
  min-width: 100%;

  .no-rows {
    min-height: 96px;
  }
}

.form-element input[type = 'password'],
.form-element .has_toggle_pw input {
  display: inline;
  height: inherit;
  position: relative;
}

.form-element button.toggle_pw {
  bottom: -2px;
  position: absolute;
  right: 0;
}

.form-element button.toggle_pw i {
  font-size: 22px;
  opacity: 0.75;
}

.form-element .mat-form-field-type-mat-select .mat-form-field-wrapper {
  min-height: 33px;
  width: 100% !important;
}

.form-element.form-explorer .mat-form-field-wrapper {
  padding-left: 0;
}

.form-element.form-explorer .mat-form-field-wrapper,
.form-element.form-explorer .mat-form-field-flex,
.form-element.form-explorer .mat-form-field-flex input {
  border: none;
}

.form-element.form-combobox .mat-form-field-flex,
.form-element.form-combobox .mat-form-field-flex input {
  border: none;
}

.margin-for-error {
  position: static !important;
}

.mat-checkbox {
  opacity: 0.75;
}

ix-tooltip button i {
  opacity: 0.5;
}

.label-container ix-tooltip {
  margin: 0;
}

.tooltip .material-icons {
  font-size: 16px !important;
}

/* Normalize mat-select */
.mat-form-field-label-wrapper,
.mat-form-field-underline,
.mat-form-field-subscript-wrapper {
  display: none;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  border: 0;
  height: fit-content;
  overflow: hidden;
  padding: 0;
}

/* Buttons */
.mat-button,
.mat-raised-button {
  font-weight: 400 !important;
}

.mat-button,
.mat-flat-button {
  border-radius: 0 !important;
  text-transform: capitalize !important;
}

.mat-button[disabled] span.mat-button-wrapper,
.mat-flat-button[disabled] span.mat-button-wrapper {
  opacity: 0.5;
}

.mat-card-actions .mat-button,
.mat-card-actions .mat-flat-button {
  background: var(--btn-default-bg) !important;
  background-color: var(--btn-default-bg) !important;
  color: var(--fg2) !important;
}

.mat-button,
.mat-flat-button,
button.mat-button.btn-default,
button.mat-button.mat-default,
.mat-card-actions button.mat-default,
button.mat-default,
button.mat-button.mat-accent.btn-default,
button.mat-button[type='button']:not(button.mat-primary) {
  background: var(--btn-default-bg);
  background-color: var(--btn-default-bg);
  color: var(--fg2) !important;
}

button.mat-button[type='button']:hover,
button.mat-button.mat-accent[type='button']:hover {
  background-color: var(--alt-bg1) !important;
  color: var(--fg2) !important;
}

a.mat-button.mat-primary,
button.mat-button.mat-primary {
  background-color: var(--primary) !important;
  color: var(--primary-txt) !important;
  opacity: 1 !important;
}

a.mat-button.mat-primary:focus-visible,
button.mat-button.mat-primary:focus-visible {
  background: var(--primary-lighter) !important;
  box-shadow: none !important;
  outline: 1px solid var(--grey);
  outline-offset: 0;
}

button.mat-button.mat-accent {
  background-color: var(--accent) !important;
  color: var(--accent-txt) !important;
  opacity: 1 !important;
}

button.mat-button.mat-error,
button.mat-button.mat-red,
button.mat-button.mat-warn {
  background-color: var(--red) !important;
  color: #efefef !important;
  opacity: 1 !important;
}

button.mat-button.mat-finished,
button.mat-button.mat-green {
  background-color: var(--green) !important;
  color: #efefef !important;
  opacity: 1 !important;
}

/* Menus */

button.mat-menu-trigger.menu-toggle.mat-button {
  max-height: 36px;
}

.mat-option:hover:not(.mat-option-disabled),
.sidenav-link:hover,
.slide-in-nav-item:hover,
.mat-menu-item:hover,
.mat-button:hover {
  background-color: var(--hover-bg) !important;
}

/* Nested Cards */
.mat-card .form-card.mat-card {
  border: none;
  box-shadow: unset;
}

/* Top bar fixes */
.topbar .mat-icon-button,
.topbar .mat-icon-button .mat-icon[alt='iXsystem logo'] {
  color: var(--topbar-txt) !important;
}

/* Material Cards*/
.mat-card {
  border: solid 1px var(--contrast-lighter);
  font-family: var(--mdc-dialog-subhead-font);
  padding: 0 !important;
  position: relative;
}

#ix-slide-in-form .mat-card,
#slide-in-form .mat-card {
  border: none;
  box-shadow: none;
}

#ix-slide-in-form .ix-form-container,
#slide-in-form .ix-form-container {
  padding: 0;
}

.mat-card .mat-toolbar-row,
.mat-card .mat-card-content > div,
.mat-card .mat-card-footer {
  padding: 16px;
}

.mat-card .mat-toolbar-row {
  border-bottom: solid 1px var(--lines);
  height: 56px;
  min-height: 48px !important;
}

.mat-card .mat-toolbar-row .actions {
  position: absolute;
  right: 16px;
}

.mat-card .mat-card-footer .actions button {
  margin-right: 8px;
}

.mat-card .mat-card-footer {
  margin-top: 8px;
  padding: 0 16px;
  width: 100%;
}

/* Material Tables */

table.mat-table {
  background-color: var(--bg2) !important;
  width: 100%;
}

th.mat-table-sticky,
thead.ui-treetable-thead th,
.mat-table thead th {
  background-color: var(--contrast-darker) !important;
  background-color: rgba(0, 0, 0, 0.1);
  vertical-align: middle;
}

.ui-treetable tr,
tr.mat-header-row,
.mat-table thead tr {
  height: 48px;
  max-height: 48px;
}

.ui-treetable tr {
  border: unset;
  border-bottom: solid 1px var(--lines);
}

.ui-treetable tr,
.mat-row,
.mat-header-row,
.mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: var(--lines) !important;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  color: var(--fg2) !important;
  position: relative;
  vertical-align: middle;
}

.mat-table-sticky {
  position: sticky !important;
}

td.mat-table-sticky:hover,
td.mat-table-sticky {
  background: unset !important;
}

td.mat-cell.mat-table-sticky.hover::before {
  max-width: unset;
}

td.mat-cell.mat-table-sticky {
  background-color: var(--bg2) !important;
  overflow: hidden;
}

td.mat-cell.mat-table-sticky::before {
  content: '';
  display: block;
  height: 1000px;
  left: 0;
  max-height: inherit;
  max-width: inherit;
  position: absolute;
  top: 0;
  width: 1000px;
  z-index: -1;
}

td.mat-cell.tree-cell {
  max-width: 300px;
}

td.mat-cell .mat-icon {
  top: calc(50% - 12px);
}

td.mat-cell .mat-icon.expansion-trigger {
  font-size: 24px;
}

td.mat-cell.mat-table-sticky.hover::before,
td.mat-cell.mat-table-sticky .hover,
.ui-treetable tr:hover,
.mat-row:hover,
tr.mat-row:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}

.mat-table tr.details-row {
  height: 0;
  min-height: 0;
}

.mat-table tr.details-row td {
  width: 100%;
}

/* TreeTable Specific Rules */
.ui-treetable tr > td:nth-child(1) {
  padding-left: 16px;
}

thead.ui-treetable-thead tr {
  border-top: solid 1px var(--lines);
}

/* Material Lists */
.mat-list-item {
  border-bottom: solid 1px var(--lines) !important;
}

.value,
.mat-list-item .value {
  font-weight: 500;
}

.label,
.mat-list-item .label {
  margin-right: 8px;
}

.standard-list .label {
  opacity: 0.65;
}

mat-card.mat-card .mat-list-base {
  padding-top: 0;
}

.mat-paginator-page-size {
  margin-top: 10px !important;
}

.entity-table-controls > div {
  margin-left: 8px;
}


#row-filter.form-element {
  display: inline;
  margin: 0;
  max-height: 36px;
  width: unset !important;
}

@media #{$media-lt-sm} {
  .entity-table-controls > div {
    margin-top: 8px;
  }

  #row-filter.search-field {
    margin-top: 8px;
  }
}

#row-filter.form-element .mat-form-field-wrapper {
  max-height: 36px !important;
  width: inherit !important;
}

#row-filter.form-element .mat-form-field-wrapper .mat-input-element {
  height: 16px;
  padding-left: 8px;
  position: relative;
  top: -4px;
}

/* Expansion Panel Fixes */
.mat-expansion-panel-header span.mat-content {
  padding-top: 4px;
  vertical-align: middle !important;
}

.mat-expansion-panel-header span.mat-expansion-indicator {
  margin-top: -4px;
  position: relative;
  right: 4px;
}

.mat-expansion-panel.no-padding .mat-expansion-panel-body {
  padding: 0;
}

.mat-expansion-panel.no-padding .mat-expansion-panel-body .ui-treetable-thead>tr>th:nth-child(1) {
  padding-left: 18px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media only screen and (max-width: 750px) {
  .viewcontroller-wrapper {
    flex-grow: 100;
    margin-top: 8px;
  }
}

/* Generic styling for nested lists*/
ul.nested-list {
  list-style: circle;
}

ul.nested-list li {
  margin-left: 24px;
}

.expanded-table-container {
  height: 100%;
}

.expanded-table {
  height: 100%;
}

.expanded-table.expanded-height {
  height: 90%;
}

.expanded-table .mat-expansion-panel-body {
  padding: 0 !important;
}

.expanded-table .mat-expansion-panel-header {
  height: auto !important;
  margin: 5px 0;
  padding-bottom: inherit;
  padding-top: inherit;
}

.expanded-table .mat-expansion-panel-header span {
  padding: 0;
}


.expanded-table mat-card {
  border: 0 !important;
  margin: 0 !important;
}

.expanded-table mat-panel-title,
.expanded-table mat-panel-description {
  margin: 0 !important;
}

.expanded-table mat-panel-title {
  align-items: center;
  display: flex;
}

.form-selection-list.mat-list-base .mat-list-item .mat-list-item-content,
.form-selection-list.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0;
}

.ngxp__container {
  border-radius: 6px !important;

  &[data-popper-placement=right] {
    margin-left: 13px !important;
  }

  &[data-popper-placement=top] {
    margin-left: 11px !important;
  }

  &[data-popper-placement=bottom] {
    margin-left: 11px !important;
  }
}

@media #{$media-lt-sm} {
  .mat-button {
    font-size: 12px;
    min-width: auto;
    padding: 0 8px;
  }
}

.form-list-item .form-element {
  margin: 8px 0;
}

.mat-tab-header-pagination-chevron {
  border-color: unset !important;
  color: var(--fg2);
}

.mat-tab-header-pagination {
  background-color: var(--lines);
  opacity: 0.6;
}

.mat-tab-header-pagination-disabled {
  opacity: 0.2;
}

ix-root {
  %focused-shared {
    box-shadow: 0 0 0 1px var(--focus-brd) inset !important;
    outline-width: 0;
  }

  .cdk-focused,
  .cdk-keyboard-focused {

    @extend %focused-shared;
    background-color: var(--focus-bg) !important;
  }

  .ix-tree-node:focus-visible,
  .ix-nested-tree-node > div:focus-visible {
    @extend %focused-shared;
  }
}

// needed to correctly put box shadow on :focus-visible
ix-tree-node,
ix-nested-tree-node > div {
  padding: 1px;
}
